import React from 'react'
import comicPinkBG from "../images/comic-pink-background-small.svg";
import comicBlueBG from "../images/comic-blue-background-small.svg";
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const SectionEcoSystem = () => {
  const {homepagedata, spaceRokitLaptopMoney, spaceManEcoSystemBalloon} = useStaticQuery(graphql`
    query {
        homepagedata: file(relativePath: {eq: "settings/settings-home.md"}) {
            childMarkdownRemark {
                frontmatter {
                    ecospeach1start
                    ecospeach1
                    ecospeach1end
                    ecospeach2start
                    ecospeach2
                    ecospeach2end
                }
            }
        }
        spaceRokitLaptopMoney: file(relativePath: {eq: "faster-website-deployments-save-money.png"}) {
          childImageSharp {
            fixed(width: 885, height: 740) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        spaceManEcoSystemBalloon: file(relativePath: {eq: "jamstack-eco-system-architecture.png"}) {
          childImageSharp {
            fixed(width: 485, height: 740) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
    }
  `)
  const pageData = homepagedata.childMarkdownRemark.frontmatter
    return (
      <>
        <div id="ecosystem" className="anchorid"></div>
        <section className="container mx-auto text-center">
          <article className="comic">
            <div
              className="panel lg:flex-1 xl:w-1/2"
              style={{
                backgroundImage: "url(" + comicBlueBG + ")",
                backgroundSize: "cover",
                overflow: "hidden",
              }}
            >
              <p className="text top-left text-xl md:text-2xl z-10">
                {pageData.ecospeach1start}
              </p>
              <div className="flex-auto flex justify-between flex-col-reverse xl:flex-row">
                <div className="flex items-start xl:w-2/3 max-w-4xl xl:pt-10 pb-10 pr-4 xl:pr-0 pl-4">
                  <p className="flex-1 speech speech-rt text-xl sm:text-2xl text-right z-10">
                    {pageData.ecospeach1}
                  </p>
                </div>
                <div className="flex items-start xl:w-1/3 pt-8 pb-0 xl:pb-8 xl:pr-4">
                  <Img
                    className="bounce flex-1 max-h-64"
                    fixed={spaceManEcoSystemBalloon.childImageSharp.fixed}
                    alt="The JAMstack Eco-System Architecture"
                    title="Rokit Media Apply a JAMStack Eco-System Deployment Architecture"
                    imgStyle={{ objectFit: "contain" }}
                  />
                </div>
              </div>
              {/* <p className="text bottom-right text-xl md:text-2xl">
                {pageData.ecospeach1end}
              </p> */}
            </div>
            <div
              className="panel lg:flex-1 xl:w-1/2"
              style={{
                backgroundImage: "url(" + comicPinkBG + ")",
                backgroundSize: "cover",
                overflow: "hidden",
              }}
            >
              <p className="text top-left text-xl md:text-2xl">
                {pageData.ecospeach2start}
              </p>
              <div className="flex-auto flex justify-between flex-col-reverse xl:flex-row">
                <div className="flex items-end xl:w-1/3 xl:pt-36 pb-12 xl:pl-4">
                  <Img
                    className="flex-1 max-h-64"
                    fixed={spaceRokitLaptopMoney.childImageSharp.fixed}
                    alt="Saving Money with faster a Website Deployment"
                    title="Rokit Media Website Deployments Save Money"
                    imgStyle={{ objectFit: "contain" }}
                  />
                </div>
                <div className="flex items-start xl:w-2/3 max-w-4xl pt-10 pb-0 xl:pb-8 pr-4 pl-4 xl:pl-0">
                  <p className="flex-1 speech text-xl sm:text-2xl text-left">
                    {pageData.ecospeach2}
                  </p>
                </div>
              </div>
              <p className="text bottom-right text-xl md:text-2xl">
                <Link to="/jamstack-eco-system/">
                  {pageData.ecospeach2end}
                </Link>
              </p>
            </div>
          </article>
        </section>
      </>
    );
}

export default SectionEcoSystem