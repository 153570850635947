import React from 'react'
import greenSunburstBg from "../images/green-sunburst-background.svg"
import SuperSonicHeading from "../images/svg/SuperSonicHeading"
import RocketHeading from "../images/rocket-fast-websites.svg"
// import RocketHeading from "../images/svg/RocketHeading"

const HomeHero = () => {
    return(
        <>
            <div className="h-full relative">
                <div className="container mx-auto text-center">
                    <div className="absolute top-0 bottom-0 right-0 left-0 h-full w-full origin-top-left transform -skew-y-6 bg-green-300" style={{backgroundImage: "url(" + greenSunburstBg + ")", backgroundSize: 'cover', overflow: 'hidden', backgroundColor: '#bbdb22'}}></div>
                    <div className="flex flex-col sm:flex-row sm:items-center justify-around pt-8 sm:pt-0">
                        <div className="flex-1 z-20 px-8 pulse">
                            <SuperSonicHeading />
                        </div>
                        <div className="flex-1 z-20" width="562px" height="435px">
                            <img className="sm-shake block max-h-64 sm:max-h-128 mx-auto" width="562px" height="435px" src={RocketHeading} alt="Rocket Fast Websites and Custom Designs" title="Rocket Fast Websites and Custom Designs by Rokit Media" />
                            {/* <RocketHeading /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeHero