import React from 'react'
import comicGreenBG from "../images/comic-green-background-small.svg";
import comicBlueBG from "../images/comic-blue-background-small.svg";
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const SectionPortfolio = () => {
    const { projectOneImage, projectTwoImage } = useStaticQuery(graphql`
    query {
        projectOneImage: file(relativePath: {eq: "nine-lives-design-website-design.png"}) {
          childImageSharp {
            fluid(maxWidth: 768, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        projectTwoImage: file(relativePath: {eq: "walkers-cart-lodge-website-design.png"}) {
          childImageSharp {
            fluid(maxWidth: 768, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
    }
  `)

    return (
        <>
            <div id="portfolio" className="anchorid"></div>
            <section className="container mx-auto text-center">
                <h3 className="heading-box-text3 text-3xl md:text-4xl lg:text-5xl p-4 mx-8">
                    <span>
                        See Our Latest Web Design Projects...
                    </span>
                </h3>
                <article className="comic">
                    <div
                        className="panel w-full lg:flex-1 xl:w-1/2"
                        style={{
                            backgroundImage: "url(" + comicBlueBG + ")",
                            backgroundSize: "cover",
                            overflow: "hidden",
                        }}
                    >
                        <p className="text top-left text-xl md:text-2xl z-10">
                            <Link to="/projects/nine-lives-design/">
                                Nine Lives Design
                            </Link>
                        </p>
                        <div className="flex flex-col">
                            <div className="flex m-8">
                                <Img
                                    className="flex-1 max-h-96"
                                    fluid={projectOneImage.childImageSharp.fluid}
                                    alt="The JAMstack Eco-System Architecture"
                                    title="Website Design Bishops Stortford - Nine Lives Design"
                                    imgStyle={{ objectFit: "contain" }}
                                />
                            </div>
                            <div className="pb-12">
                                <ul className="text-base text-center z-10 font-mono flex flex-wrap justify-center">
                                    <li className="rounded-full px-2 mb-2 bg-purple-400 shadow text-white mx-2 uppercase inline">Bespoke</li>
                                    <li className="rounded-full px-2 mb-2 bg-red-400 shadow text-white mx-2 uppercase inline">Design</li>
                                    <li className="rounded-full px-2 mb-2 bg-blue-400 shadow text-white mx-2 uppercase inline">Development</li>
                                    <li className="rounded-full px-2 mb-2 bg-green-400 shadow text-white mx-2 uppercase inline">Responsive</li>
                                    <li className="rounded-full px-2 mb-2 bg-yellow-400 shadow text-white mx-2 uppercase inline">SEO</li>
                                    <li className="rounded-full px-2 mb-2 bg-indigo-400 shadow text-white mx-2 uppercase inline">CMS</li>
                                    <li className="rounded-full px-2 mb-2 bg-purple-400 shadow text-white mx-2 uppercase inline">Gatsby</li>
                                    <li className="rounded-full px-2 mb-2 bg-pink-400 shadow text-white mx-2 uppercase inline">React</li>
                                    <li className="rounded-full px-2 mb-2 bg-blue-400 shadow text-white mx-2 uppercase inline">Calendly</li>
                                    <li className="rounded-full px-2 mb-2 bg-yellow-400 shadow text-white mx-2 uppercase inline">Gallery</li>
                                    <li className="rounded-full px-2 mb-2 bg-green-400 shadow text-white mx-2 uppercase inline">Perfect Core Web Vitals!</li>
                                </ul>
                            </div>
                        </div>
                        <p className="text bottom-right text-xl md:text-2xl">
                            <a href="https://www.ninelivesdesign.co.uk/" target="_blank" rel="noreferrer">
                                Visit Website...
                            </a>
                        </p>
                    </div>
                    <div
                        className="panel w-full lg:flex-1 xl:w-1/2"
                        style={{
                            backgroundImage: "url(" + comicGreenBG + ")",
                            backgroundSize: "cover",
                            overflow: "hidden",
                        }}
                    >
                        <p className="text top-left text-xl md:text-2xl z-10">
                            <Link to="/projects/walkers-cart-lodge/">
                                Walkers Cart Lodge
                            </Link>
                        </p>
                        <div className="flex flex-col">
                            <div className="m-8">
                                <Img
                                    className="flex-1 max-h-96"
                                    fluid={projectTwoImage.childImageSharp.fluid}
                                    alt="The JAMstack Eco-System Architecture"
                                    title="Website Design Bishops Stortford - Walkers Cart Lodge"
                                    imgStyle={{ objectFit: "contain" }}
                                />
                            </div>
                            <div className="pb-12">
                                <ul className="text-base text-center z-10 font-mono flex flex-wrap justify-center">
                                    <li className="rounded-full px-2 mb-2 bg-purple-400 shadow text-white mx-2 uppercase inline">Bespoke</li>
                                    <li className="rounded-full px-2 mb-2 bg-red-400 shadow text-white mx-2 uppercase inline">Design</li>
                                    <li className="rounded-full px-2 mb-2 bg-blue-400 shadow text-white mx-2 uppercase inline">Development</li>
                                    <li className="rounded-full px-2 mb-2 bg-green-400 shadow text-white mx-2 uppercase inline">Responsive</li>
                                    <li className="rounded-full px-2 mb-2 bg-yellow-400 shadow text-white mx-2 uppercase inline">SEO</li>
                                    <li className="rounded-full px-2 mb-2 bg-indigo-400 shadow text-white mx-2 uppercase inline">CMS</li>
                                    <li className="rounded-full px-2 mb-2 bg-purple-400 shadow text-white mx-2 uppercase inline">Gatsby</li>
                                    <li className="rounded-full px-2 mb-2 bg-pink-400 shadow text-white mx-2 uppercase inline">React</li>
                                    <li className="rounded-full px-2 mb-2 bg-blue-400 shadow text-white mx-2 uppercase inline">Calendar</li>
                                    <li className="rounded-full px-2 mb-2 bg-yellow-400 shadow text-white mx-2 uppercase inline">Tailwind CSS</li>
                                    <li className="rounded-full px-2 mb-2 bg-green-400 shadow text-white mx-2 uppercase inline">Perfect Core Web Vitals!</li>
                                </ul>
                            </div>
                        </div>
                        <p className="text bottom-right text-xl md:text-2xl">
                            <a href="https://www.walkerscartlodge.co.uk/" target="_blank" rel="noreferrer">
                                Visit Website...
                            </a>
                        </p>
                    </div>
                </article>
            </section>
        </>
    );
}

export default SectionPortfolio