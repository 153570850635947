import React from 'react';
import comicGreenBG from "../images/comic-green-background-small.svg";
import comicPinkBG from "../images/comic-pink-background-small.svg";
import comicYellowBG from "../images/comic-yellow-background-small.svg";
import comicBlueBG from "../images/comic-blue-background-small.svg";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";
import SpaceManHead from '../images/spaceManHead';

const SectionQuote = ({children, bg, asset, row, animate, toptext, bottomtext, bottomlink}) => {
  const {mobileFirstAprocah, spaceManSpeedRank, wordPressLogo} = useStaticQuery(graphql`
  query {
    mobileFirstAprocah: file(relativePath: {eq: "mobile-first-aproach.png"}) {
      childImageSharp {
        fixed(width: 847, height: 740) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    spaceManSpeedRank: file(relativePath: {eq: "improved-serps-with-faster-websites.png"}) {
      childImageSharp {
        fixed(width: 461, height: 740) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    wordPressLogo: file(relativePath: {eq: "wp.png"}) {
      childImageSharp {
        fixed(width: 600, height: 600) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
  `)
    let setBg = comicGreenBG
    let setAsset = SpaceManHead
    toptext = toptext || ''
    bottomtext = bottomtext || ''
    if (bg === 'yellow'){
        setBg = comicYellowBG
    } else if (bg === 'blue') {
        setBg = comicBlueBG
    } else if (bg === 'green') {
        setBg = comicGreenBG
    } else if (bg === 'pink') {
        setBg = comicPinkBG
    }
    if (asset === 'spacemanhead') {
        setAsset = <SpaceManHead />
    } else if (asset === 'scooter') {
        setAsset = <Img fixed={spaceManSpeedRank.childImageSharp.fixed} imgStyle={{ objectFit: "contain" }} style={{maxWidth: "250px"}} className="flex-1 max-h-64" alt="Improved SERPs with Faster Websites" title="Search Engine Results Pages Improved with Faster Websites" />
    } else if (asset === 'mobilefirst') {
        setAsset = <Img fixed={mobileFirstAprocah.childImageSharp.fixed} imgStyle={{ objectFit: "contain" }} style={{maxWidth: "250px"}} className="flex-1 max-h-64" alt="Mobile First Aproach" title="Rokit Media Apply a Mobile First Aproach" />
    } else if (asset === 'wp') {
        setAsset = <Img fixed={wordPressLogo.childImageSharp.fixed} imgStyle={{ objectFit: "contain" }} style={{width: "250px", height: "250px"}} className="flex-1" alt="WordPress Design Services" title="Rokit Media WordPress Design Services" />
  }
    return (
      <>
        <section className="container mx-auto text-center">
          <article className="comic">
            <div className="panel" style={{backgroundImage: "url(" + setBg + ")", backgroundSize: "cover", overflow: "hidden",}}>
              {toptext === "" ? ("") : (<p className="text top-left text-xl md:text-2xl">
                  {toptext}
                </p>)}
                <div className={row === true? "flex-auto flex flex-col lg:flex-row pb-4 mt-4" : "flex-auto flex flex-col mt-4"}>
                {asset === 'wp'?
                  <div className={row === true? "speech mt-8 mx-8 text-xl sm:text-2xl text-left lg:w-2/3" : "speech speech-b mt-8 mx-8 text-xl sm:text-2xl text-left"}>
                    <span className={row === true? "tracking-wider" : "tracking-wider"}>
                      {children}
                    </span>
                  </div>
                  : 
                  <div className={row === true? "speech mt-8 mx-8 text-3xl text-center lg:w-2/3" : "speech speech-b mt-8 mx-8 text-3xl text-center"} style={{ fontFamily: "Bangers, cursive" }}>
                    <span className={row === true? "uppercase text-2xl sm:text-3xl md:text-5xl tracking-wider text-gray-500" : "uppercase text-2xl sm:text-3xl md:text-5xl tracking-wider text-gray-500"}>
                      {children}
                    </span>
                  </div>
                }
                {animate === true ? (
                  <div className={row === true? "flex items-end justify-center pt-0 pb-4 pr-4 lg:w-1/3 shake" : "flex items-end justify-center pt-0 pb-0 shake"}>
                    {setAsset}
                  </div>
                ) : (
                  <div className={row === true? "flex items-end justify-center pt-0 pb-4 pr-4 lg:w-1/3" : "flex items-end justify-center pt-0 pb-0"}>
                    {setAsset}
                  </div>
                )}
              </div>
              {bottomtext === "" ? ("") : (
              <div className="pt-4">
                <p className="text bottom-right text-xl md:text-2xl">
                  <Link to={bottomlink}>
                    {bottomtext}
                  </Link>
                </p>
              </div>
              )}
            </div>
          </article>
        </section>
      </>
    );
}

export default SectionQuote