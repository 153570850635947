import React from "react"
import comicGreenBG from "../images/comic-green-background-small.svg"
import comicPinkBG from "../images/comic-pink-background-small.svg";
import comicYellowBG from "../images/comic-yellow-background-small.svg";
import comicBlueBG from "../images/comic-blue-background-small.svg";
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const SectionServices = () => {
  const { homepagedata, spaceManRecipes, spaceManDeveloper, spaceManEcommerce, websiteLaunch } = useStaticQuery(graphql`
    query {
        homepagedata: file(relativePath: {eq: "settings/settings-home.md"}) {
            childMarkdownRemark {
                frontmatter {
                    servicesspeach1start
                    servicesspeach1
                    servicesspeach1end
                    servicesspeach2start
                    servicesspeach2
                    servicesspeach2end
                    servicesspeach3start
                    servicesspeach3
                    servicesspeach3end
                    servicesspeach4start
                    servicesspeach4
                    servicesspeach4end
                }
            }
        }
        spaceManRecipes: file(relativePath: {eq: "bespoke-website-design-recipes.png"}) {
          childImageSharp {
            fixed(width: 535, height: 740) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        spaceManDeveloper: file(relativePath: {eq: "develop-websites-to-improve-serps.png"}) {
          childImageSharp {
            fixed(width: 760, height: 740) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        spaceManEcommerce: file(relativePath: {eq: "ecommerce-solutions-that-engage-with-your-audience.png"}) {
          childImageSharp {
            fixed(width: 618, height: 740) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
        websiteLaunch: file(relativePath: {eq: "bespoke-website-design-and-development.png"}) {
          childImageSharp {
            fixed(width: 747, height: 740) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
    }
  `)
  const pageData = homepagedata.childMarkdownRemark.frontmatter
  return (
    <>
      <div id="services" className="anchorid"></div>
      <section className="container mx-auto text-center">
        <h3 className="heading-box-text2 text-3xl md:text-4xl lg:text-5xl p-4 mx-8">
          <span>
            Web Design Services...
          </span>
        </h3>
        <article className="comic">
          <div
            className="panel lg:flex-1 xl:w-1/2"
            style={{
              backgroundImage: "url(" + comicPinkBG + ")",
              backgroundSize: "cover",
              overflow: "hidden",
            }}
          >
            <p className="text top-left text-xl md:text-2xl">
              {pageData.servicesspeach1start}
            </p>
            <div className="flex-auto flex justify-between flex-col-reverse xl:flex-row">
              <div className="flex items-end xl:w-1/3 xl:pt-36 pb-8 xl:pl-4">
                <Img className="flex-1 max-h-64" fixed={spaceManRecipes.childImageSharp.fixed} imgStyle={{ objectFit: "contain" }} alt="Bespoke Website Design Recipes" title="Rokit Media offer bespoke Website Design and Development Recipes" />
              </div>
              <div className="flex items-start xl:w-2/3 max-w-4xl pt-10 pb-0 xl:pb-8 pr-4 pl-4 xl:pl-0">
                <p className="flex-1 speech speech-lb text-xl sm:text-2xl text-left">
                  {pageData.servicesspeach1}
                </p>
              </div>
            </div>
          </div>
          <div
            className="panel lg:flex-1 xl:w-1/2"
            style={{
              backgroundImage: "url(" + comicYellowBG + ")",
              backgroundSize: "cover",
              overflow: "hidden",
            }}
          >
            <p className="text top-left text-xl md:text-2xl z-10">
              {pageData.servicesspeach2start}
            </p>
            <div className="flex-auto flex justify-between flex-col-reverse xl:flex-row">
              <div className="flex items-start xl:w-2/3 max-w-4xl xl:pt-10 pb-10 pr-4 xl:pr-0 pl-4">
                <p className="flex-1 speech speech-rt text-xl sm:text-2xl text-right z-10">
                  {pageData.servicesspeach2}
                </p>
              </div>
              <div className="flex items-start xl:w-1/3 pt-8 pb-0 xl:pr-4">
                <Img className="flex-1 max-h-64" fixed={spaceManDeveloper.childImageSharp.fixed} imgStyle={{ objectFit: "contain" }} alt="Develop Websites To Improve Search Engine Results Pages" title="Rokit Media Develop Websites To Improve SERPs" />
              </div>
            </div>
          </div>
        </article>

        <article className="comic">
          <div
            className="panel lg:flex-1 xl:w-1/2"
            style={{
              backgroundImage: "url(" + comicGreenBG + ")",
              backgroundSize: "cover",
              overflow: "hidden",
            }}
          >
            <p className="text top-left text-xl md:text-2xl">
              {pageData.servicesspeach3start}
            </p>
            <div className="flex-auto flex justify-between flex-col-reverse xl:flex-row">
              <div className="flex items-end xl:w-1/3 xl:pt-36 pb-10 xl:pl-4">
                <Img className="flex-1 max-h-64" fixed={spaceManEcommerce.childImageSharp.fixed} imgStyle={{ objectFit: "contain" }} alt="Ecommerce Solutions that Engage with your Audience" title="Rokit Media Build Engaging Ecommerce Solutions" />
              </div>
              <div className="flex items-start xl:w-2/3 max-w-4xl pt-10 pb-0 xl:pb-8 pr-4 pl-4 xl:pl-0">
                <p className="flex-1 speech speech-lb text-xl sm:text-2xl text-left">
                  {pageData.servicesspeach3}
                </p>
              </div>
            </div>
          </div>
          <div
            className="panel lg:flex-1 xl:w-1/2"
            style={{
              backgroundImage: "url(" + comicBlueBG + ")",
              backgroundSize: "cover",
              overflow: "hidden",
            }}
          >
            <p className="text top-left text-xl md:text-2xl z-10">
              {pageData.servicesspeach4start}
            </p>
            <div className="flex-auto flex justify-between flex-col-reverse xl:flex-row">
              <div className="flex items-start xl:w-2/3 max-w-4xl xl:pt-10 pb-10 pr-4 xl:pr-0 pl-4">
                <p className="flex-1 speech speech-rt text-xl sm:text-2xl text-right z-10">
                  {pageData.servicesspeach4}
                </p>
              </div>
              <div className="flex items-start xl:w-1/3 pt-12 pb-0 xl:pr-4">
                <Img className="flex-1 max-h-64 pt-8" fixed={websiteLaunch.childImageSharp.fixed} imgStyle={{ objectFit: "contain" }} alt="Bespoke Website Design and development" title="Rokit Media build bespoke Website Designs" />
              </div>
            </div>
            <p className="text bottom-right text-xl md:text-2xl">
              <Link to="/web-design-services/">
                {pageData.servicesspeach4end}
              </Link>
            </p>
          </div>
        </article>
      </section>
    </>
  );
};

export default SectionServices;
