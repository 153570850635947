import React from 'react'
import comicYellowBG from "../images/comic-yellow-background-small.svg";
import comicBlueBG from "../images/comic-blue-background-small.svg";
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const SectionAbout = () => {
    const {homepagedata, spaceManOnRokitWithPhone, spaceAlienFlyingShip} = useStaticQuery(graphql`
        query {
            homepagedata: file(relativePath: {eq: "settings/settings-home.md"}) {
                childMarkdownRemark {
                    frontmatter {
                        aboutspeach1start
                        aboutspeach1
                        aboutspeach1end
                        aboutspeach2start
                        aboutspeach2
                        aboutspeach2end
                    }
                }
            }
            spaceManOnRokitWithPhone: file(relativePath: {eq: "mobile-first-approach-website-design.png"}) {
                childImageSharp {
                  fixed(width: 667, height: 740) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
                }
            }
            spaceAlienFlyingShip: file(relativePath: {eq: "out-of-this-world-modern-website-design.png"}) {
                childImageSharp {
                  fixed(width: 889, height: 740) {
                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                  }
                }
            }
        }
    `)
    const pageData = homepagedata.childMarkdownRemark.frontmatter
    return (
        <>
            <div id="about" className="anchorid"></div>
            <section className="container mx-auto text-center pt-4">
                <article className="comic">
                    <div className="panel lg:flex-1 xl:w-1/2" style={{backgroundImage: "url(" + comicYellowBG + ")", backgroundSize: 'cover', overflow: 'hidden'}}>
                        <p className="text top-left text-xl md:text-2xl z-20">
                            {pageData.aboutspeach1start}
                        </p>
                        <div className="flex-auto flex justify-between flex-col-reverse xl:flex-row">
                            <div className="flex items-start xl:w-2/3 max-w-4xl xl:pt-10 pb-10 pr-4 xl:pr-0 pl-4">
                                <p className="flex-1 speech speech-rt text-xl sm:text-2xl text-right">
                                    {pageData.aboutspeach1}
                                </p>
                            </div>
                            <div className="flex items-start xl:w-1/3 pt-8 pb-0 xl:pb-8 xl:pr-4">
                                <Img className="hoverwave flex-1 max-h-64" fixed={spaceAlienFlyingShip.childImageSharp.fixed} imgStyle={{ objectFit: "contain" }} alt="Out of this World Modern Website Design" title="Modern Website Design by Rokit Media" />
                            </div>
                        </div>
                        {/* <p className="text bottom-right text-xl md:text-2xl">.
                            {pageData.aboutspeach1end}
                        </p> */}
                    </div>
                    <div className="panel lg:flex-1 xl:w-1/2" style={{backgroundImage: "url(" + comicBlueBG + ")", backgroundSize: 'cover', overflow: 'hidden'}}>
                        <p className="text top-left text-xl md:text-2xl">
                            {pageData.aboutspeach2start}
                        </p>
                        <div className="flex-auto flex justify-between flex-col-reverse xl:flex-row">
                            <div className="flex items-end xl:w-1/3 xl:pt-36 pb-8 xl:pl-4">
                                <Img className="bounce flex-1 max-h-64" fixed={spaceManOnRokitWithPhone.childImageSharp.fixed} imgStyle={{ objectFit: "contain" }} alt="Mobile First Approach Website Design" title="Rokit Media Applies a Mobile First Approach to Website Design" />
                            </div>
                            <div className="flex items-start xl:w-2/3 max-w-4xl pt-10 pb-0 xl:pb-12 pr-4 pl-4 xl:pl-0">
                                <p className="flex-1 speech speech-lb text-xl sm:text-2xl text-left">
                                    {pageData.aboutspeach2}
                                </p>
                            </div>
                        </div>
                        <p className="text bottom-right text-xl md:text-2xl">
                            <Link to="/about-rokit-media/">
                                {pageData.aboutspeach2end}
                            </Link>
                        </p>
                    </div>
                </article>
            </section>
        </>
    )
}

export default SectionAbout