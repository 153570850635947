import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HomeHero from '../components/HomeHero'
import SectionServiceList from '../components/SectionServiceList'
import SectionAbout from '../components/SectionAbout'
import SectionServices from '../components/SectionServices'
import SectionEcoSystem from '../components/SectionEcoSystem'
import ContactForm from '../components/ContactForm'
import SectionQuote from '../components/SectionQuote'
import BlogPosts from "../components/BlogPosts"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import SectionPortfolio from '../components/SectionPortfolio'
import Brands from '../components/Brands'

const IndexPage = (props) => {
    const {homepagedata} = useStaticQuery(graphql`
        query {
            homepagedata: file(relativePath: {eq: "settings/settings-home.md"}) {
                childMarkdownRemark {
                    frontmatter {
                        quote1start
                        quote1
                        quote2start
                        quote2
                        quote3start
                        quote3
                        quote4start
                        quote4
                    }
                }
            }
        }
    `)
    const pageData = homepagedata.childMarkdownRemark.frontmatter
    
    return (
        <>
            <Layout location={props.location}>
                <Helmet>
                    <meta name="google-site-verification" content="twXxkbsCLWx-JVbbKbiTIDhLggyjWXFzmNoyblQ3Egs" />
                </Helmet>
                <SEO />
                <HomeHero />
                <SectionServiceList />
                <SectionAbout />
                <SectionQuote bg="green" asset="spacemanhead" row={false} animate={false} toptext={pageData.quote1start}>
                    {pageData.quote1}
                    <p className="text-red-600 pulse pt-4"><Link to="/website-performance-review/">"GET A FREE WEBSITE PERFORMANCE REVIEW"</Link></p>
                </SectionQuote>
                <SectionPortfolio />
                <SectionServices />
                <SectionQuote bg="green" asset="wp" row={true} animate={false} toptext={pageData.quote4start} bottomtext="Find out more about our services..." bottomlink="/web-design-services/">
                    {pageData.quote4}
                </SectionQuote>
                <SectionQuote bg="yellow" asset="mobilefirst" row={true} animate={false} toptext={pageData.quote2start} bottomtext="Find out more about our services..." bottomlink="/web-design-services/">
                    {pageData.quote2}
                </SectionQuote>
                <SectionEcoSystem />
                <SectionQuote bg="green" asset="scooter" row={true} animate={true} toptext={pageData.quote3start} bottomtext="GET A FREE PERFORMANCE REVIEW..." bottomlink="/website-performance-review/">
                    {pageData.quote3}
                </SectionQuote>
                <Brands />
                <BlogPosts />
                <ContactForm />
            </Layout>
        </>
    )
}

export default IndexPage