import React from "react"

const SpaceManHead = () => {

    return (
        <>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 665 309"
      width="300px"
    >
      <g transform="translate(-37.342 -132.141)">
        <path fill="none" d="M37.342 132.141H702.27V440.626H37.342z"></path>
        <clipPath id="_clip1">
          <path d="M37.342 132.141H702.27V440.626H37.342z"></path>
        </clipPath>
        <g clipPath="url(#_clip1)">
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M2588.81 1197.27c-5.63.36-10.48-3.91-10.84-9.54l-18.77-293.555c-.36-5.63 3.92-10.484 9.54-10.844 59.05-3.774 109.98 41.035 113.76 100.084l6.4 100.105c3.77 59.05-41.04 109.98-100.09 113.75z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#371A45"
            fillRule="nonzero"
            d="M2575.73 885.138c-2.28 0-4.56.072-6.85.221a8.135 8.135 0 00-5.63 2.759 8.14 8.14 0 00-2.02 5.927L2580 1187.6c.28 4.5 4.2 8 8.68 7.65 28.02-1.79 53.66-14.39 72.21-35.47 18.55-21.07 27.77-48.11 25.98-76.13l-6.4-100.106c-1.79-28.017-14.38-53.662-35.46-72.207-19.35-17.026-43.74-26.199-69.28-26.199zm13.08 312.132h.05-.05zm-.65 2.05c-6.4 0-11.8-4.99-12.22-11.46l-18.76-293.556c-.43-6.738 4.7-12.569 11.43-12.999 60.11-3.803 112.07 41.91 115.91 101.981l6.4 100.104c1.86 29.1-7.72 57.18-26.98 79.08-19.26 21.89-45.9 34.97-75 36.83-.26.01-.52.02-.78.02z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#B9AFBE"
            fillRule="nonzero"
            d="M2604.12 1175.74c4.34-21.4 7.34-43.15 8.98-65.2 22.88-6.74 42.41-20.85 56.03-39.45l.87 13.64c2.74 42.86-25.76 80.63-65.88 91.01z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#371A45"
            fillRule="nonzero"
            d="M2578.68 902.091l17.6 275.299c44.19-7.22 76.64-47.06 73.72-92.66l-6.4-100.107c-1.5-23.512-12.07-45.032-29.76-60.593-15.49-13.625-34.81-21.258-55.16-21.939zm10.13 295.179h.05-.05zm-.67 18.96c-15.22 0-28.08-11.88-29.07-27.29l-18.76-293.558c-1.02-16.041 11.19-29.923 27.23-30.944 33.57-2.171 66.03 8.914 91.32 31.162 25.28 22.244 40.39 53.001 42.53 86.606l6.4 100.104c2.15 33.61-8.92 66.04-31.16 91.32-22.24 25.28-53 40.39-86.61 42.54-.63.04-1.26.06-1.88.06z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M1599.78 945.272c5.63-.359 10.48 3.912 10.84 9.54l18.76 293.558c.37 5.63-3.91 10.48-9.54 10.84-59.04 3.78-109.97-41.03-113.75-100.08l-6.4-100.1c-3.77-59.054 41.04-109.983 100.09-113.758z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#371A45"
            fillRule="nonzero"
            d="M1599.9 947.299c-28.01 1.79-53.65 14.385-72.2 35.464-18.55 21.077-27.77 48.117-25.98 76.137l6.4 100.1c1.79 28.02 14.38 53.66 35.46 72.21 21.08 18.54 48.14 27.8 76.14 25.98a8.146 8.146 0 005.62-2.76 8.148 8.148 0 002.02-5.93l-18.77-293.559c-.28-4.5-4.17-7.955-8.69-7.642zm12.96 314.171c-26.54 0-51.86-9.53-71.96-27.21-21.89-19.27-34.97-45.9-36.84-75l-6.39-100.11c-1.86-29.09 7.72-57.18 26.98-79.07 19.26-21.892 45.9-34.973 75-36.834 6.81-.341 12.56 4.703 13 11.436l18.76 293.558c.21 3.26-.87 6.41-3.03 8.87-2.15 2.46-5.14 3.92-8.4 4.13-2.39.15-4.76.23-7.12.23z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#B9AFBE"
            fillRule="nonzero"
            d="M1601.93 1239.83c-17.39-2.17-33.74-9.48-47.18-21.32-17.69-15.56-28.26-37.07-29.76-60.59l-.88-13.73c16.11 15.34 36.95 25.66 59.89 28.66a515.12 515.12 0 0017.93 66.98z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#371A45"
            fillRule="nonzero"
            d="M1592.31 965.151c-44.19 7.227-76.64 47.069-73.72 92.669l6.4 100.1c1.5 23.52 12.07 45.03 29.76 60.59 15.48 13.63 34.81 21.26 55.16 21.94l-17.6-275.299zm20.61 313.219c-65.87 0-121.46-51.38-125.72-118.03l-6.4-100.11c-2.15-33.6 8.92-66.034 31.16-91.315 22.24-25.283 53-40.39 86.61-42.537 16.06-1.039 29.91 11.188 30.94 27.22l18.77 293.562c.5 7.77-2.06 15.27-7.2 21.11-5.15 5.85-12.26 9.34-20.03 9.84-2.72.17-5.43.26-8.13.26z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#fff"
            fillRule="nonzero"
            d="M2595.61 1074.85c-1.98 276.84-228 499.65-504.84 497.67-276.83-1.98-499.65-228-497.67-504.84 1.98-276.832 228-499.647 504.84-497.667 276.84 1.98 499.65 228.004 497.67 504.837z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#371A45"
            fillRule="nonzero"
            d="M2094.25 572.031c-131.98 0-256.28 50.947-350.38 143.708-94.96 93.616-147.79 218.611-148.74 351.961-.95 133.35 50.08 259.09 143.7 354.05 93.61 94.97 218.61 147.79 351.96 148.74 133.22.93 259.08-50.07 354.05-143.69 94.96-93.62 147.79-218.61 148.74-351.96.95-133.349-50.08-259.089-143.69-354.054-93.62-94.966-218.62-147.791-351.97-148.743-1.22-.007-2.45-.012-3.67-.012zm.16 1002.539c-1.23 0-2.42-.01-3.65-.02-277.52-1.98-501.67-229.37-499.69-506.88 1.98-276.277 227.4-499.7 503.23-499.7 1.23 0 2.42.005 3.65.015 134.44.96 260.45 54.214 354.83 149.952 94.37 95.737 145.82 222.498 144.86 356.933-1.98 276.27-227.4 499.7-503.23 499.7z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#371A45"
            fillRule="nonzero"
            d="M2094.26 588.935c-127.52 0-247.61 49.224-338.52 138.843-91.75 90.447-142.79 211.209-143.71 340.042-.92 128.83 48.39 250.31 138.83 342.06 90.45 91.75 211.21 142.79 340.05 143.71 128.99.76 250.31-48.38 342.06-138.83 91.75-90.45 142.79-211.21 143.71-340.04.92-128.837-48.39-250.316-138.83-342.066-90.45-91.751-211.21-142.786-340.04-143.707a603.04 603.04 0 00-3.55-.012zm.2 1002.535c-1.28 0-2.54 0-3.82-.01-138.95-1-269.19-56.04-366.74-154.99-97.55-98.96-150.73-229.97-149.73-368.92.99-138.947 56.04-269.191 154.99-366.74 98.95-97.547 230.06-150.781 368.91-149.729 138.95.994 269.2 56.035 366.74 154.988 97.55 98.955 150.73 229.973 149.73 368.921-.99 138.95-56.03 269.19-154.99 366.74-98.04 96.65-227.57 149.74-365.09 149.74z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#371A45"
            fillRule="nonzero"
            d="M2593.92 1087.72c.78 98.08-30.34 189.4-84.19 265.18-40.38 56.81-207.42 157.01-414.64 160.52-192.13 3.25-367.77-97.8-409.05-153.97-55.05-74.91-87.62-165.72-88.4-263.81-2.06-259.566 220.09-368.946 495.22-371.135 275.12-2.189 498.99 103.642 501.06 363.215z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#371A45"
            fillRule="nonzero"
            d="M2100.35 735.771c-2.52 0-4.98.01-7.4.029-146.38 1.165-486.57 38.552-484.01 359.75.74 92.53 30.54 181.47 86.2 257.21 38.93 52.97 206.31 149.43 390.85 149.44 2.98 0 5.93-.03 8.92-.08 208.36-3.53 369.43-104.85 405.61-155.76 54.45-76.62 82.84-166.02 82.1-258.55-2.51-315.892-332.13-352.039-482.27-352.039zm-14.55 789.019c-194.61.01-366.39-100.86-408.86-158.65-58.49-79.59-89.82-173.1-90.59-270.41-1.01-126.71 49.01-224.805 148.67-291.558 87.09-58.34 210.8-89.795 357.75-90.965 147-1.198 271.14 28.316 359.15 85.259 100.71 65.161 152.28 162.449 153.29 289.164.78 97.31-29.06 191.3-86.28 271.81-39.65 55.8-204.63 161.56-423.64 165.27-3.17.06-6.34.08-9.49.08z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#C3BAC7"
            fillRule="nonzero"
            d="M2424.69 1141.85c-2.68 0-5.38-.23-8.07-.7-34.98-6.05-56.57-50.09-48.22-98.37 7.71-44.553 38.22-77.142 70.38-77.142 2.69 0 5.39.228 8.08.694 28.27 4.89 47.79 34.588 49.84 71.288-3.6 21.73-8.45 43.08-14.46 63.97-13.66 24.53-35.21 40.26-57.55 40.26zM2351.26 1270.31c-3.1 0-6.22-.47-9.28-1.45-18.38-5.88-27.72-28.01-20.86-49.43 5.71-17.85 20.88-29.57 36.41-29.57 3.1 0 6.22.47 9.28 1.45 18.38 5.88 27.72 28.02 20.86 49.43-5.71 17.85-20.88 29.57-36.41 29.57z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#2A1A3B"
            fillRule="nonzero"
            d="M2085.99 1502.2c-57.72 0-113.76-9.44-164.72-24.04 239.56-18.23 428.22-133.87 476.17-201.36 37.7-53.03 66.61-112.06 84.8-175.21 6-10.79 10.48-23.28 12.84-36.89 1.6-9.26 2.1-18.37 1.62-27.08 5.25-31.71 7.85-64.236 7.58-97.373-.19-26.486-2.25-51.716-5.98-75.722 50.09 51.635 83.53 123.506 84.32 223.285.74 92.53-27.65 181.93-82.1 258.55-36.18 50.91-197.25 152.23-405.61 155.76-2.99.05-5.94.08-8.92.08z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#2A1A3B"
            fillRule="nonzero"
            d="M2085.87 1513.51c-69.89 0-137.3-13.58-196.59-33.51 10.76-.42 21.43-1.03 31.99-1.84 50.96 14.6 107 24.04 164.72 24.04 2.98 0 5.93-.03 8.92-.08 208.36-3.53 369.43-104.85 405.61-155.76 54.45-76.62 82.84-166.02 82.1-258.55-.79-99.779-34.23-171.65-84.32-223.285a465.722 465.722 0 00-3.2-18.192c61.36 57.624 97.97 137.117 98.81 241.367.78 98.11-30.35 189.42-84.17 265.2-40.39 56.81-207.44 157-414.66 160.53-3.07.06-6.15.08-9.21.08z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#858290"
            fillRule="nonzero"
            d="M2482.24 1101.59a565.851 565.851 0 0014.46-63.97c.48 8.71-.02 17.82-1.62 27.08-2.36 13.61-6.84 26.1-12.84 36.89z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#371A45"
            fillRule="nonzero"
            d="M1633.41 874.005h.05-.05zm14.94 11.627l-29.72-23.455 13.96 11.175-14.87-9.932c88.01-131.763 259.96-208.3 471.77-209.991 210.49-1.589 383.25 69.586 474.79 195.491 5.29 6.956 5.24 16.907-.67 23.807-6.81 7.941-18.73 8.887-26.67 2.083a19.845 19.845 0 01-3.07-3.319c-84.2-116.086-246.1-181.865-444.07-180.195-199.07 1.587-359.66 71.989-440.59 193.155-.27.404-.55.8-.86 1.181z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <path
            fill="#C3BAC7"
            fillRule="nonzero"
            d="M1710.74 1249.17s-82.43-255.428 54.76-358.567c102.33-76.927 238.78-97.128 299.65-97.128 20.74 0 32.7 2.345 31.55 5.591 0 0-219.38 54.961-304 229.414-65.25 134.52-81.96 220.69-81.96 220.69z"
            transform="matrix(.34292 0 0 .34292 -451.964 -28.94)"
          ></path>
          <g id="spacemanHand">
            <path
              fill="#fff"
              fillRule="nonzero"
              d="M2941.38 1436.75c-30.72 104.8-264.02 298.86-598.72 308.57-32.38.99-65.7.19-99.88-2.56l-29.87-127.56-23.9-102.1s51.97-9.47 123.02-28.36c56.14-14.86 124.16-35.59 187.92-62.1 60.11-24.99 116.49-55.14 155.39-90.41 0 0 2.13-104.98 31.91-115.68 36.35-13.06 63.28 60.78 63.28 60.78s82.65-85.06 130.88-76.83c71.71 12.21 93.72 121.32 59.97 236.25z"
              transform="scale(1.19537) rotate(-65.064 539.563 369.862) matrix(.32453 0 0 .32453 -276.364 -182.072)"
            ></path>
            <path
              fill="#B9AFBE"
              fillRule="nonzero"
              d="M2358.05 1725.82c-5.69-29.7-12.09-59.34-20.33-88.82 34.63 7.19 68.81 10.47 102.21 10.48 226.65.02 418.77-151.04 492.63-255.65-2.33 13.05-5.44 26.3-9.33 39.57-26.73 91.05-244.44 279.11-565.18 294.42z"
              transform="scale(1.19537) rotate(-65.064 539.563 369.862) matrix(.32453 0 0 .32453 -276.364 -182.072)"
            ></path>
            <path
              fill="#371A45"
              fillRule="nonzero"
              d="M2316.31 1764.68c-24.55 0-49.54-.98-75.03-3.03-10.42-.84-18.19-9.97-17.35-20.39.84-10.43 10.01-18.19 20.39-17.35 382.25 30.79 649.14-191.07 678.91-292.51 21.24-72.41 19.17-144.5-5.3-183.67-10.12-16.19-23.46-25.8-39.68-28.57-26.43-4.5-82.66 39.03-114.13 71.38l-21.03 21.62-10.33-28.33c-8.33-22.67-27.47-53.01-38.81-49.56-9.39 6.7-18.74 53.33-19.69 98.34l-.17 8.15-6.05 5.49c-134.86 122.14-461.77 182.95-475.62 185.48-10.28 1.87-20.14-4.94-22.02-15.22-1.88-10.29 4.94-20.15 15.22-22.03 3.24-.59 320.2-59.55 451.03-171.05 1.48-38.84 8.99-112.03 44.19-124.69 35.72-12.88 61.71 20.71 75.77 46.5 30.3-27.49 85.65-70.61 128-63.41 26.95 4.59 49.57 20.44 65.44 45.84 30.39 48.66 34.04 130.81 9.51 214.39-34.36 117.07-287.61 322.6-643.25 322.62z"
              transform="scale(1.19537) rotate(-65.064 539.563 369.862) matrix(.32453 0 0 .32453 -276.364 -182.072)"
            ></path>
            <path
              fill="#CDC6D0"
              fillRule="nonzero"
              d="M2321.17 1575.2c-1.29-11.74-2.49-23.02-3.6-33.76 39.74-18.9 77.16-42.97 111.43-71.85 15.76-5.35 31.64-11.04 47.45-17.1-42.93 50.52-95.7 92.42-155.28 122.71z"
              transform="scale(1.19537) rotate(-65.064 539.563 369.862) matrix(.32453 0 0 .32453 -276.364 -182.072)"
            ></path>
            <path
              fill="#371A45"
              fillRule="nonzero"
              d="M2429 1469.59a530.08 530.08 0 0030.55-27.86c1.74-1.71 3.45-3.42 5.16-5.15 11.82-4.45 23.6-9.1 35.24-13.94a501.96 501.96 0 01-23.5 29.85c-15.81 6.06-31.69 11.75-47.45 17.1zM2627.13 1705.96c-2.78 0-5.58-.69-8.17-2.14-84.28-47.34-90.43-275.15-90.86-300.97-.15-9.23 7.21-16.84 16.43-16.99h.29c9.1 0 16.55 7.3 16.7 16.43 1.09 64.84 16.46 240.18 73.81 272.38 8.04 4.52 10.91 14.71 6.38 22.76-3.06 5.46-8.74 8.53-14.58 8.53zM2765.5 1278.45c.17 1.04.25 2.98.36 4.59l.32 5.12c.29 3.45.41 6.94.76 10.42.46 7.01 1.34 14.01 2.34 21.06.97 7.09 2.45 14.11 3.83 21.46 1.1 7.55 3 14.91 3.92 23.9-8.04-3.7-14.76-9.35-20.23-15.91-2.85-3.25-5.08-6.85-7.35-10.5-1.96-3.74-4.06-7.51-5.63-11.47-3.41-7.84-5.82-16.09-7.35-24.5-.64-4.22-1.28-8.47-1.41-12.79-.09-2.16-.17-4.32-.06-6.52.05-2.28.21-4.23.58-7.07 1.08-8.16 8.57-13.91 16.73-12.83 6.69.88 11.75 6.06 12.77 12.4l.42 2.64z"
              transform="scale(1.19537) rotate(-65.064 539.563 369.862) matrix(.32453 0 0 .32453 -276.364 -182.072)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
        </>
    )
}

export default SpaceManHead