import React from 'react'

import logoReact from '../images/logo-react.svg'
import logoGatsby from '../images/logo-gatsby.svg'
import logoNext from '../images/logo-next.svg'
import logoAws from '../images/logo-aws.svg'
import logoGcp from '../images/logo-gcp.svg'
import logoShopify from '../images/logo-shopify.svg'
import logoWP from '../images/logo-wp.svg'

const Brands = () => {
    return (
        <section className="container mx-auto text-center mt-16">
            <h3 className="heading-box-text2 text-3xl md:text-4xl lg:text-5xl p-4 mx-8">
                <span>
                    Technology Stack We Use
                </span>
            </h3>
            <div className="grid grid-cols-2 mx-6 mt-6 gap-6 lg:grid-cols-4">
                <div className="flex items-center"><img src={logoReact} alt="React JS" className="max-h-36 mx-auto" /></div>
                <div className="flex items-center"><img src={logoAws} alt="Amazon Web Services" className="max-h-36 mx-auto" /></div>
                <div className="flex items-center"><img src={logoNext} alt="Next JS" className="max-h-36 mx-auto" /></div>
                <div className="flex items-center"><img src={logoGatsby} alt="Gatsby JS" className="max-h-36 mx-auto" /></div>
            </div>
            <div className="grid grid-cols-1 mx-6 mt-6 mb-16 gap-6">
                <div className="flex items-center py-3"><img src={logoGcp} alt="Google Cloud Platform" className="max-h-36 mx-auto" /></div>
                <div className="flex items-center py-3"><img src={logoShopify} alt="Shopify Partners" className="max-h-36 mx-auto" /></div>
                <div className="flex items-center py-3"><img src={logoWP} alt="WordPress" className="max-h-36 mx-auto" /></div>
            </div>
        </section>
    )
}

export default Brands