import React from 'react'
import AnchorLink from "react-anchor-link-smooth-scroll"

const SectionServiceList = () => {
    return (
        <>
            <div className="container mx-auto">
                <div className="flex flex-wrap justify-around items-center px-8">
                    <AnchorLink href="#services">
                        <h1 className="pulse heading-box-text1 text-3xl sm:text-3xl md:text-4xl lg:text-5xl whitespace-nowrap"><span>Custom Website Design!</span></h1>
                    </AnchorLink>
                    <AnchorLink href="#services">
                        <h2 className="pulse heading-box-text2 text-3xl sm:text-3xl md:text-4xl lg:text-5xl whitespace-nowrap"><span>Faster Websites</span></h2>
                    </AnchorLink>
                    <AnchorLink href="#services">
                        <h2 className="pulse heading-box-text3 text-3xl sm:text-3xl md:text-4xl lg:text-5xl sm:text-right whitespace-nowrap"><span>Ecommerce</span></h2>
                    </AnchorLink>
                    <AnchorLink href="#services">
                        <h2 className="pulse heading-box-text1 text-3xl sm:text-3xl md:text-4xl lg:text-5xl whitespace-nowrap"><span>Blogs</span></h2>
                    </AnchorLink>
                    <AnchorLink href="#services">
                        <h2 className="pulse heading-box-text2 text-3xl sm:text-3xl md:text-4xl lg:text-5xl whitespace-nowrap"><span>Portfolios</span></h2>
                    </AnchorLink>
                    <AnchorLink href="#services">
                        <h2 className="pulse heading-box-text3 text-3xl sm:text-3xl md:text-4xl lg:text-5xl sm:text-right whitespace-nowrap"><span>CMS</span></h2>
                    </AnchorLink>
                    <AnchorLink href="#services">
                        <h2 className="pulse heading-box-text1 text-3xl sm:text-3xl md:text-4xl lg:text-5xl sm:text-right whitespace-nowrap"><span>Web Page Design</span></h2>
                    </AnchorLink>
                    <AnchorLink href="#services">
                        <h2 className="pulse heading-box-text2 text-3xl sm:text-3xl md:text-4xl lg:text-5xl sm:text-right whitespace-nowrap"><span>Improved SERP<small>s</small></span></h2>
                    </AnchorLink>
                    <AnchorLink href="#services">
                        <h2 className="pulse heading-box-text3 text-3xl sm:text-3xl md:text-4xl lg:text-5xl sm:text-right whitespace-nowrap"><span>SEO Friendly</span></h2>
                    </AnchorLink>
                </div>
            </div>
        </>
    )
}

export default SectionServiceList